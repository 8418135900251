import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

//  CookieConsent.showPreferences(); 

const ccObjToArr = (obj) => {
    let arr = [];
    for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            arr = arr.concat(obj[prop]);
        }
    }
    return arr
}
const ccArrToObj = (arr, prop) => {
    let obj = {};
    arr.forEach(s=>obj[s]=prop);
    return obj;
}

export function setCookiesLanguage(lang_shortcut) {
    CookieConsent.setLanguage(lang_shortcut, true);
}

export function showCookieConsent() {
    CookieConsent.showPreferences();
}

export function initCookieConsent() {
    CookieConsent.run({
        guiOptions: {
            consentModal: {
                layout: "box",
                position: "bottom left",
                equalWeightButtons: true,
                flipButtons: false
            },
            preferencesModal: {
                layout: "box",
                position: "right",
                equalWeightButtons: true,
                flipButtons: false
            }
        },
        onFirstConsent: ({cookie}) => {
            let granted = ccObjToArr(cookie.services);
            if (granted.length) {
                let obj = ccArrToObj(granted, 'granted');
                window.gtag('consent', 'update', obj);
            }
        },
        onChange: function({changedServices}){
            let accepted = ccObjToArr(CookieConsent.getUserPreferences().acceptedServices);
            if (accepted.length) {
                let acceptedObj = ccArrToObj(accepted, 'granted');
                window.gtag('consent', 'update', acceptedObj);
            }
            let rejected = ccObjToArr(CookieConsent.getUserPreferences().rejectedServices);
            if (rejected.length) {
                let rejectedObj = ccArrToObj(rejected, 'denied');
                window.gtag('consent', 'update', rejectedObj);
            }
        },
        onModalShow:({modalName}) => {
            if (modalName==='preferencesModal') {
                document.getElementsByClassName('pm--box')[0].dataset['lenisPrevent'] = true;
            }
        },
        categories: {
            necessary: {
                enabled: true,
                readOnly: true
            },
            functionality: {
                services: {
                    functionality_storage: {}
                }
            },
            analytics: {
                services: {
                    analytics_storage: {}
                }
            },
            marketing: {
                services: {
                    ad_storage: {},
                    ad_user_data: {}
                }
            },
            personalization: {
                services: {
                    ad_personalization:{},
                    personalization_storage: {}
                }
            }
        },
        language: {
            default: "cs",
            translations: {
                cs: {
                    consentModal: {
                        title: `Používáme cookies!`,
                        description: `Aby web fungoval správně, využíváme nezbytná cookies. Jestli se o nich chcete dozvědět víc nebo si navolit dobrovolná cookies, <button class="cc__link" data-cc="show-preferencesModal">klikněte zde.</button>`,
                        acceptAllBtn: "Přijímám vše",
                        acceptNecessaryBtn: "Odmítám vše",
                        showPreferencesBtn: "Podrobné nastavení"
                    },
                    preferencesModal: {
                        title: "Podrobné nastavení cookies",
                        acceptAllBtn: "Přijímám vše",
                        acceptNecessaryBtn: "Odmítám vše",
                        savePreferencesBtn: "Uložit nastavení",
                        closeIconLabel: "Zavřít",
                        serviceCounterLabel: "",
                        sections: [
                            {
                                title: "Využití cookies",
                                description: "Cookies používáme nejen k zabezpečení základních funkcí webu, ale taky abychom vám poskytli tu nejlepší uživatelskou zkušenost a oslovovali vás vhodnými reklamami. Zde si můžete zvolit jednotlivé kategorie a nastavit, jak máme s vašimi daty pracovat."
                            },
                            {
                                title: "Nezbytná základní cookies <span class=\"pm__badge\">Nelze zakázat</span>",
                                description: "Ty jsou nezbytně nutné pro fungování webu a jeho funkcí, které se rozhodnete využívat. Bez nich by náš web nefungoval, proto tato kategorie nemůže být vypnutá.",
                                linkedCategory: "necessary"
                            },
                            {
                                title: "Funkční cookies",
                                description: "Tyto cookies nám umožňují zapamatovat si Vaše základní volby a vylepšují uživatelský komfort. Jde například o zapamatování si jazyka či umožnění zůstat trvale přihlášen.",
                                linkedCategory: "functionality"
                            },{
                                title: "Analytické cookies",
                                description: "Analytické soubory cookie měří počet návštěv a shromažďují informace o zdrojích návštěvnosti. Používáme je ke zlepšení fungování našeho webu. Pomáhají nám zjistit, které stránky jsou nejoblíbenější nebo jak se na naší platformě pohybujete. Neudělení souhlasu nemá vliv na fungování stránek.",
                                linkedCategory: "analytics"
                            },
                            {
                                title: "Marketingové cookies",
                                description: "Marketingové (reklamní) cookies používáme my nebo naši partneři, abychom vám mohli zobrazit vhodné obsahy nebo reklamy, a to jak na našich stránkách, tak na stránkách třetích subjektů. Neudělení souhlasu nemá vliv na fungování stránek.",
                                linkedCategory: "marketing"
                            },
                            {
                                title: "Personalizované cookies",
                                description: "Personalizované cookies používáme, abychom přizpůsobili zobrazování reklam a obsahu, které lépe odpovídají vašim zájmům a potřebám. Neudělení souhlasu nemá vliv na fungování stránek.",
                                linkedCategory: "personalization"
                            },
                            {
                                title: "Více informací",
                                description: "V případě jiných dotazů ohledně využívání cookies nás neváhejte nás <a class=\"cc__link\" href=\"mailto:zdenek@agenturagen.cz\">kontaktovat</a>."
                            }
                        ]
                    }
                },
                en: {
                    consentModal: {
                        title: `We use cookies!`,
                        description: `To ensure the website functions correctly, we use necessary cookies. If you want to learn more about them or set optional cookies, <button class="cc__link" data-cc="show-preferencesModal">click here.</button>`,
                        acceptAllBtn: "Accept all",
                        acceptNecessaryBtn: "Reject All",
                        showPreferencesBtn: "Detailed Settings"
                    },
                    preferencesModal: {
                        title: "Detailed Cookie Settings",
                        acceptAllBtn: "Accept All",
                        acceptNecessaryBtn: "Reject All",
                        savePreferencesBtn: "Save Settings",
                        closeIconLabel: "Close",
                        serviceCounterLabel: "",
                        sections: [
                            {
                                title: "Use of Cookies",
                                description: "We use cookies to ensure the website's basic functions, provide you with the best user experience, and target you with appropriate advertisements. Here, you can choose individual categories and set how we should handle your data."
                            },
                            {
                                title: "Essential Basic Cookies <span class=\"pm__badge\">Cannot be disabled</span>",
                                description: "These are necessary for the website's functioning and the features you choose to use. Our website would not work without them, so this category cannot be turned off.",
                                linkedCategory: "necessary"
                            },
                            {
                                title: "Functional Cookies",
                                description: "These cookies allow us to remember your basic choices and improve user comfort. For example, remembering the language or allowing you to stay logged in permanently.",
                                linkedCategory: "functionality"
                            },{
                                title: "Analytical Cookies",
                                description: "Analytical cookies measure the number of visits and collect information about traffic sources. We use them to improve the functioning of our website. They help us find out which pages are the most popular or how you move around our platform. Not giving consent does not affect the functioning of the site.",
                                linkedCategory: "analytics"
                            },
                            {
                                title: "Marketing Cookies",
                                description: "We or our partners use marketing (advertising) cookies to show you appropriate content or advertisements, both on our site and on third-party sites. Not giving consent does not affect the functioning of the site.",
                                linkedCategory: "marketing"
                            },
                            {
                                title: "Personalized Cookies",
                                description: "We use personalized cookies to tailor the display of advertisements and content that better match your interests and needs. Not giving consent does not affect the functioning of the site.",
                                linkedCategory: "personalization"
                            },
                            {
                                title: "More Information",
                                description: "If you have any other questions regarding the use of cookies, please do not hesitate to <a class='cc__link' href='mailto:zdenek@agenturagen.cz'>contact us</a>."
                            }
                        ]
                    }
                },
                de: {
                    consentModal: {
                        title: `Wir verwenden Cookies!`,
                        description: `Damit die Website ordnungsgemäß funktioniert, verwenden wir notwendige Cookies. Wenn Sie mehr darüber erfahren möchten oder sich für freiwillige Cookies entscheiden möchten, <button class="cc__link" data-cc="show-preferencesModal">klicken Sie hier.</button>`,
                        acceptAllBtn: "Akzeptiere alles",
                        acceptNecessaryBtn: "Alles ablehnen",
                        showPreferencesBtn: "Detaillierte Einstellungen "
                    },
                    preferencesModal: {
                        title: "Detaillierte Einstellungen ",
                        acceptAllBtn: "Akzeptiere alles ",
                        acceptNecessaryBtn: "Alles ablehnen",
                        savePreferencesBtn: "Detaillierte Einstellungen",
                        closeIconLabel: "Schließen",
                        serviceCounterLabel: "",
                        sections: [
                            {
                                title: "Verwendung von Cookies ",
                                description: "Wir verwenden Cookies nicht nur, um die Grundfunktionen der Website sicherzustellen, sondern auch, um Ihnen das beste Benutzererlebnis zu bieten und Sie mit passenden Werbeanzeigen zu erreichen. Hier können Sie einzelne Kategorien auswählen und festlegen, wie wir mit Ihren Daten umgehen sollen. "
                            },
                            {
                                title: "Notwendige Grund-cookies <span class=\"pm__badge\">Dürfen nicht abgelehnt werden</span>",
                                description: "Diese Cookies sind für das Funktionieren der Website und der Funktionen, die Sie nutzen möchten, unbedingt erforderlich. Ohne sie würde unsere Website nicht funktionieren, daher kann diese Kategorie nicht deaktiviert werden.",
                                linkedCategory: "necessary"
                            },
                            {
                                title: "Funktionelle Cookies",
                                description: "Diese Cookies ermöglichen es uns, Ihre grundlegenden Entscheidungen zu speichern und den Benutzerkomfort zu verbessern. Dabei geht es zum Beispiel darum, sich die Sprache zu merken oder dauerhaft eingeloggt zu bleiben.",
                                linkedCategory: "functionality"
                            },{
                                title: "Analytische Cookies",
                                description: "Analytische Cookies messen die Anzahl der Besuche und sammeln Informationen über Verkehrsquellen. Wir nutzen sie, um die Funktionalität unserer Website zu verbessern. Sie helfen uns herauszufinden, welche Seiten am beliebtesten sind oder wie Sie auf unserer Plattform navigieren. Die Nichterteilung der Einwilligung hat keinen Einfluss auf die Funktionsfähigkeit der Website.",
                                linkedCategory: "analytics"
                            },
                            {
                                title: "Marketing-Cookies ",
                                description: "Marketing-(Werbe-)Cookies werden von uns oder unseren Partnern verwendet, damit wir Ihnen passende Inhalte oder Werbung zeigen können, sowohl auf unserer Website als auch auf den Websites Dritter. Die Nichterteilung der Einwilligung hat keinen Einfluss auf die Funktionsfähigkeit der Website.",
                                linkedCategory: "marketing"
                            },
                            {
                                title: "Personalisierte Cookies ",
                                description: "Wir verwenden personalisierte Cookies, um die Anzeige von Anzeigen und Inhalten besser an Ihre Interessen und Bedürfnisse anzupassen. Die Nichterteilung der Einwilligung hat keinen Einfluss auf die Funktionsfähigkeit der Website.",
                                linkedCategory: "personalization"
                            },
                            {
                                title: "Mehr Informationen",
                                description: "Für weitere Fragen zur Verwendung von Cookies stehen <a class=\"cc__link\" href=\"mailto:zdenek@agenturagen.cz\">wir Ihnen gerne zur Verfügung</a>."
                            }
                        ]
                    }
                },
                es: {
                    consentModal: {
                        title: `¡Usamos cookies!`,
                        description: `Para que el sitio web funcione correctamente, utilizamos las cookies necesarias. Si desea obtener más información sobre ellas o elegir cookies voluntarias, <button class=\"cc__link\" data-cc=\"show-preferencesModal\">haga clic aquí.</button>`,
                        acceptAllBtn: "Acepto todo",
                        acceptNecessaryBtn: "Rechazar todo",
                        showPreferencesBtn: "Configuración detallada"
                    },
                    preferencesModal: {
                        title: "Configuración detallada de cookies",
                        acceptAllBtn: "Acepto todo",
                        acceptNecessaryBtn: "Rechazar todo",
                        savePreferencesBtn: "Guardar configuración",
                        closeIconLabel: "Cerrar",
                        serviceCounterLabel: "",
                        sections: [
                            {
                                title: "Uso de cookies",
                                description: "Utilizamos cookies no sólo para asegurar las funciones básicas del sitio web, sino también para ofrecerle una mejor experiencia de usuario y dirigirnos a usted con anuncios adecuados. Aquí puede elegir entre diferentes categorías individuales y configurar cómo debemos manejar sus datos."
                            },
                            {
                                title: "Se requieren cookies esenciales <span class=\"pm__badge\">No se pueden desactivar</span>",
                                description: "Estas cookies son absolutamente necesarias para el funcionamiento del sitio web y las funciones que usted decida utilizar. Sin ellas, nuestro sitio web no funcionaría, por lo tanto, esta categoría no se puede desactivar.",
                                linkedCategory: "necessary"
                            },
                            {
                                title: "Cookies funcionales",
                                description: "Estas cookies nos permiten grabar sus elecciones básicas y mejorar la comodidad del usuario. Esto es, por ejemplo, recordar su idioma o permitirle permanecer conectado permanentemente.",
                                linkedCategory: "functionality"
                            },{
                                title: "Cookies analíticas",
                                description: "Las cookies analíticas miden el número de visitas y recopilan información sobre el comportamiento de los usuarios de los sitios web. Las utilizamos para mejorar el funcionamiento de nuestro sitio web. Nos ayudan a saber qué páginas son las más populares o cómo se mueve por nuestra plataforma. No dar el consentimiento no afecta al funcionamiento del sitio.",
                                linkedCategory: "analytics"
                            },
                            {
                                title: "Cookies de publicidad comportamental",
                                description: "Nosotros o nuestros socios utilizamos cookies de publicidad para poder mostrarle contenidos o anuncios adecuados, tanto en nuestro sitio web como en los sitios web de terceros. La falta de consentimiento no afecta al funcionamiento del sitio web.",
                                linkedCategory: "marketing"
                            },
                            {
                                title: "Cookies personalizadas",
                                description: "Utilizamos cookies de preferencias para personalizar la visualización de anuncios y contenidos que se adapten mejor a sus intereses y necesidades. No dar su consentimiento no afecta al funcionamiento del sitio.",
                                linkedCategory: "personalization"
                            },
                            {
                                title: "Más información",
                                description: "En caso de tener más preguntas sobre el uso de cookies, no dude en <a class=\"cc__link\" href=\"mailto:e-mail@agenturagen.cz\">contactarnos</a>."
                            }
                        ]
                    }
                }
            }
        }
    })
}