import { useContext } from "react";
import { AppContext } from "../App";
import ReactPlayer from 'react-player';
import parse from 'html-react-parser';

const EnVideo = () => {
    const {video_title, video_perex, video_link} = useContext(AppContext);
    return (
        <section className="py-top px-2vw">
            <h2 className='text-center tablet:text-left'>{video_title}</h2>
            <p className='text-center tablet:text-left py-4'>{parse(video_perex)}</p>
            <div className="w-full max-w-[1000px] m-auto aspect-video videocontainer">
                <ReactPlayer url={video_link} />
            </div>
        </section>
    )
}

export default EnVideo;